globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"dev"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const {
  datadog: { isEnabled: isDatadogEnabled },
} = getConfig().publicRuntimeConfig;

if (!isDatadogEnabled) {
  Sentry.init({
    dsn: 'https://095d36ee1c2c4620a7a4e2d9d116e10e@o123542.ingest.sentry.io/4504362924113920',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0001, // 0.01%

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0, // 100%

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0001, // 0.01%

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    ignoreErrors: [/SecurityError: Blocked a frame with origin/],
  });
}
